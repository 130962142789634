import React from "react"
import {createGlobalStyle, ThemeProvider} from "styled-components"
import Theme from "./src/themes/theme"

const GlobalStyles = createGlobalStyle ` 

  :root {
    --black-blue: #222F3E;
    --gray: #576574;
    --off-black: #0c0c1d;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
    /* scroll-behavior: smooth; */
  }

  body {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  /* Removes default browser button styles */
  input, button, submit { border:none; }


`


export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
  <GlobalStyles />
    {element}
    
  </ThemeProvider>
)
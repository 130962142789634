// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newlayout-2-js": () => import("./../../../src/pages/newlayout2.js" /* webpackChunkName: "component---src-pages-newlayout-2-js" */),
  "component---src-pages-newlayout-js": () => import("./../../../src/pages/newlayout.js" /* webpackChunkName: "component---src-pages-newlayout-js" */),
  "component---src-pages-projects-bootstrap-design-js": () => import("./../../../src/pages/projects/BootstrapDesign.js" /* webpackChunkName: "component---src-pages-projects-bootstrap-design-js" */),
  "component---src-pages-projects-buoy-js": () => import("./../../../src/pages/projects/Buoy.js" /* webpackChunkName: "component---src-pages-projects-buoy-js" */),
  "component---src-pages-projects-calendar-central-js": () => import("./../../../src/pages/projects/CalendarCentral.js" /* webpackChunkName: "component---src-pages-projects-calendar-central-js" */),
  "component---src-pages-projects-candor-js": () => import("./../../../src/pages/projects/Candor.js" /* webpackChunkName: "component---src-pages-projects-candor-js" */),
  "component---src-pages-projects-echo-logistics-js": () => import("./../../../src/pages/projects/EchoLogistics.js" /* webpackChunkName: "component---src-pages-projects-echo-logistics-js" */),
  "component---src-pages-projects-family-app-js": () => import("./../../../src/pages/projects/FamilyApp.js" /* webpackChunkName: "component---src-pages-projects-family-app-js" */),
  "component---src-pages-projects-marks-hub-js": () => import("./../../../src/pages/projects/MarksHub.js" /* webpackChunkName: "component---src-pages-projects-marks-hub-js" */),
  "component---src-pages-projects-point-of-care-js": () => import("./../../../src/pages/projects/PointOfCare.js" /* webpackChunkName: "component---src-pages-projects-point-of-care-js" */)
}

